// 全ページ共通スタイル
// headerやカラム用のスタイルは"_layout.scss"へ
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html{
	font-family: $ff_jp;
	color: $c_text;
	font-size: 20px;
	line-height: 1.5;

	@if $type == sp{
		-webkit-overflow-scrolling: touch;
	}
}


body{
	background-color: #fff;
	-webkit-text-size-adjust: none;
    position: relative;
    left: 0;
    
	@if $type == sp{
		width: 100%;
		position: relative;
		background: #fff;
	}

	@if $type == pc{
	}
}

a{
	color: $c_href;
	&:hover{
		color: $c_href;
        opacity: 0.8;
		//text-decoration: underline;
	}
	&:visited{
		color: $c_href;
	}

	&:active{
		color: $c_href;
	}
}
img{
	border: none;
	vertical-align: top;
	@if $type == pc{
	}
	@if $type == sp{
		max-width: 100%;
		height: auto;
	}
}
.forPC{	@if $type == sp{display: none!important;}}
.forSP{	@if $type == pc{display: none!important;}}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.25);
    top: 0;
    left: 0;
}

.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}

/*--header------------------------------------*/
header{
    @if $type == sp{
        width: 100%;
        height: 55px;
        background-color: rgba(255,255,255,0.75);
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        
        .header_inner{
            width: 100%;
            margin: 0 auto;
            padding: 16px 0 0;
            text-align: center;
            position: relative;
            height: 55px;
            .logo{
                img{
                    width: 46%;
                }
            }
            .menu{
                position: absolute;
                right: 2%;
                top: 15px;
                img{
                    width: 47%;
                }
            }
        }
    }

    @if $type == pc{
        width: 100%;
        background-color: rgba(255,255,255,0.75);
        z-index: 2;
        position: relative;
        
        .header_inner{
            max-width: 960px;
            width: 100%;
            margin: 0 auto;
            padding: 18px 0 16px;
            overflow: hidden;
            text-align: center;
            .logo{
                display: inline-block;
                &:hover{
                    opacity: 1;
                }
                img{
                    width: 244px;
                }
            }
            .menu{
                display: inline-block;
                float: right;
                cursor: pointer;
                img{
                    width: 30px;
                }
                &:hover{
                    opacity: .8;
                }
            }
        }
    }
}

/*--footer------------------------------------*/
footer{
    width: 100%;
        @if $type == sp{
            .footer_inner{
                .totop{
                    height: 45px;
                    width: 100%;
                    background: url(../images/common/arrow_T.png) no-repeat center #25381c;
                    display: block;
                    background-size: 15px;
                }
                .copyright{
                    width: 100%;
                    background-color: #4f6936;
                    height: 40px;
                    text-align: center;
                    padding-top: 14px;
                    font-size: 10px;
                    display: block;
                    color: #fff;
                }
            }
        }

        @if $type == pc{
            background-color: #4f6936;
            width: 100%;
            .footer_inner{
                max-width: 960px;
                width: 100%;
                height: 60px;
                margin: 0 auto;
                position: relative;
                text-align: center;
                color: #fff;
                font-size: 12px;
                padding-top: 22px;
            
            .totop{
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

/*--slide_menu------------------------------------*/
#slide_menu{
    @if $type == sp{
        width: 100%;
        position: fixed;
        top: 0;
        right: -100%;
        transition: all .5s ease;
        .close{
            text-align: right;
            display: block;
            img{
                height: 55px;
                width: 55px;
            }
        }
        ul{
            background-color: #506b36;
            li{
                border-bottom: 1px dotted #fff;
                position: relative;
                a{
                    display: block;
                    height: 60px;
                    padding: 23px 15px 0;
                    img{
                        width: 25%;
                    }
                }
                &:last-child{
                    border-bottom: none;
                }
                &:after{
                    content: "";
                    background: url(../images/common/arrow_R.png) no-repeat 0 0;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 43%;
                    right: 15px;
                    background-size: cover;
                }
            }
        }
        &.open{
            right: 0;
        }
    }

    @if $type == pc{
        position: fixed;
        top: 0;
        right: -760px;
        width: 760px;
        height: 100%;
        background: #506b36;
        padding: 20px;
        overflow: hidden;
        z-index: 100;
        transition: all .5s ease;
        
        .slide_menu_inner{
            width: 240px;
            .close{
                display: block;
                float: right;
                margin-right: 10px;
                img{
                    width: 35px;
                }
                &:hover{
                    opacity: .8;
                }
            }
            ul{
                padding-top: 100px;

                li{
                    margin-bottom: 58px;
                    text-align: center;
                    a{
                    }
                }
            }
        }
        &.open{
            right: 0;
            
        }
    }
}




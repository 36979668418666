// 変数設定

// Break Point ---------------------------------------------
$breakpoint_sp		:600px;
$breakpoint_tb		:800px;
$breakpoint_pc		:$breakpoint_sp + 1;

// Color ---------------------------------------------------
// font、backgroundやborderの色を指定します。
// 頭文字"c_"から命名します。
$c_base : #f6ab00;//サイトカラー
$c_text : #2a2a2a; // テキストに使うフォントカラー
$c_href : #004994; // テキスト内で使うリンクカラー

// Font Family
// フォントファミリーはここで指定します。
// 頭文字"ff_"から命名します。
$ff_jp  : 'Meiryo','Hiragino Kaku Gothic ProN',sans-serif; //日本語用
$ff_eng	: 'Open Sans', 'sans-serif','meiryo'; //英語用

.sample{
	@if $type == sp{
	}
	@if $type == pc{
	}
}
#contents{
    @if $type == sp{
        width: 100%;
        background: url(../images/home/bg_contents_sp.jpg) no-repeat 0 0;
        background-position: center;
        height: auto;
        margin-top: -64px;
        background-size: cover;
        
        .logo_area{
            padding: 169px 0 50px;
            position: relative;
            width: 100%;
            margin: 0 auto;
            h1.logo{
                margin: 0 auto;
                padding: 0;
                text-align: center;
                img{
                    width: 43%;
                }
            }
            .caption{
                position: absolute;
                left: 0;
                bottom: 5px;
            }
        }
        
        .greeting{
            background-color: rgba(255,255,255,0.75);
            .greeting_inner{
                width: 96%;
                margin: 0 auto;
                padding: 35px 0 60px;
                text-align: center;
                h2{
                    margin-bottom: 30px;
                    img{
                        width: 24%;
                    }
                }
                p{
                    font-size: 14px;
                    line-height: 2;
                    color: #394a2f;
                    font-family: "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;
                    font-weight: bold;
                }
            }
        }
        .lawyers{
            background-color: rgba(0,0,0,0.3);
            .lawyers_inner{
                padding: 35px 0 60px;
                width: 92%;
                margin: 0 auto;
                text-align: center;
                h2{
                    margin-bottom: 31px;
                    img{
                        width: 28%;
                    }
                }
                ul{
                    li{
                        list-style: none;
                        margin-bottom: 15px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        a{
                            color: #fff;
                            font-size: 17px;
                            font-family: "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;
                            background: url(../images/home/btn_lawyers_sp_off.png) no-repeat 0 0;
                            width: 100%;
                            height: 63px;
                            text-decoration: none;
                            background-size: 100% 100%;
                            display: block;
                            text-align: center;
                            padding-top: 20px;
                        }
                    }
                }
            }
        }

    }
    @if $type == pc{
        width: 100%;
        background: url(../images/home/bg_contents.jpg) no-repeat 0 0;
        background-position: center;
        height: 1271px;
        margin-top: -70px;
        
        .logo_area{
            padding: 224px 0 165px;
            position: relative;
            width: 100%;
            margin: 0 auto;
            max-width: 960px;
            h1.logo{
                margin: 0;
                padding: 0;
                img{
                    width: 213px;
                }
            }
            .caption{
                position: absolute;
                left: 0;
                bottom: 5px;
            }
        }
        
        .greeting{
            background-color: rgba(255,255,255,0.75);
            .greeting_inner{
                max-width: 960px;
                width: 100%;
                margin: 0 auto;
                padding: 60px 0 80px;
                text-align: center;
                h2{
                    margin-bottom: 58px;
                }
                p{
                    font-size: 16px;
                    line-height: 2;
                    color: #394a2f;
                    font-family: "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;
                    font-weight: bold;
                }
            }
        }
        .lawyers{
            background-color: rgba(0,0,0,0.2);
            .lawyers_inner{
                padding: 60px 0 88px;
                max-width: 960px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                h2{
                    margin-bottom: 58px;
                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    li{
                        list-style: none;
                        width: 30%;
                        margin-left: 40px;
                        &:first-of-type{
                            margin-left: 0;
                        }
                        a{
                            color: #fff;
                            font-size: 24px;
                            font-family: "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;
                            width: 100%;
                            height: 101px;
                            text-decoration: none;
                            display: block;
                            text-align: center;
                            padding-top: 29px;
                            background-color: rgba(#fff,.3);
                            position: relative;
                            z-index: 1;
                            border: 1px solid #fff;
                            box-shadow: -3px 3px 0 rgba(#fff,.2);

                            &:before{
                                content: "";
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 5px;
                                left: -5px;
                                background-color: rgba(#000,.2);
                                z-index: 0;
                            }
                            &:after{
                                content: "";
                                display: block;
                                width: 12px;
                                height: 12px;
                                transform: rotate(45deg);
                                border-top: 2px solid #fff;
                                border-right: 2px solid #fff;
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -7px;
                            }
                            &:hover{
                                span{
                                    color: #453534;
                                    background-color: rgba(#fff,.8);
                                }

                                &:before{
                                }
                                
                                &:after{
                                    content: "";
                                    display: block;
                                    width: 12px;
                                    height: 12px;
                                    transform: rotate(45deg);
                                    border-top: 2px solid #453534;
                                    border-right: 2px solid #453534;
                                    position: absolute;
                                    right: 20px;
                                    top: 50%;
                                    margin-top: -7px;
                                    z-index: 6;
                                }
                            }
                            span{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 5;
                                font-weight: 700;
                                transition: all .3s;
                            }
                        }
                    }
                }
            }
        }
    }
}
.access{
    @if $type == sp{
        width: 100%;
        background: url(../images/home/bg_access_sp.jpg) no-repeat 0 0;
        height: auto;
        background-position: center;
        padding: 33px 0 45px;
        background-size: cover;
        .access_inner{
            text-align: center;
            h2{
                margin-bottom: 30px;
                img{
                    width: 18%;
                }
            }
            .gmap{
                margin-bottom: 40px;
                iframe{
                    width: 100%;
                    height: 263px;
                }
            }
            .btn_area{
                .shosai{
                    display: block;
                    margin: 0 auto;
                    background: url(../images/home/btn_shosai_sp_off.png) no-repeat 0 0;
                    width: 92%;
                    height: 53px;
                    background-size: 100% 100%;
                }
            }
        }
        
    }
    @if $type == pc{
        width: 100%;
        background: url(../images/home/bg_acess.jpg) no-repeat 0 0;
        height: 687px;
        background-position: center;

        .access_inner{
            max-width: 960px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            padding: 59px 0 84px;
            h2{
                margin-bottom: 60px;
            }
            .gmap{
                margin-bottom: 30px;
                iframe{
                    width: 100%;
                    height: 351px;
                }
            }
            .btn_area{
                .shosai{
                    display: block;
                    margin: 0 auto;
                    background: url(../images/home/btn_shosai_off.png) no-repeat 0 0;
                    width: 222px;
                    height: 53px;
                    &:hover{
                        background: url(../images/home/btn_shosai_on.png) no-repeat 0 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
}